<template>
    <BT-Blade-Item
        @edit="loadPolicy"
        @saved="panelV = 0"
        bladeName="standing-purchase-order"
        :canSave="panelV == 0"
        :editToggle="editToggle"
        :hideBladeHeader="hideBladeHeader"
        :loadingMsg="loadingMsg"
        navigation="standing-purchase-order"
        :onCanSave="canSaveOrder"
        :onGetSave="getOrderToSave"
        :onNew="newOrder"
        :onPullSuccessAsync="orderPulled"
        :small="false"
        :title="title"
        transparent>
        <template v-slot:raw="{ data, isChanged, item, msg, save }">
            <v-container v-if="item != null" class="pa-0 pa-sm-3 pb-sm-0" fluid>
                <v-slide-x-reverse-transition hide-on-leave group tag="div" class="d-flex no-gutters">
                    <v-col v-if="!$vuetify.breakpoint.mobile || currentCol == 0" key="1">
                        <v-card flat outlined class="ma-0 pa-0"><!-- style="height: calc(100vh - 156px)"-->
                            <v-toolbar v-if="!$vuetify.breakpoint.mobile || data.isNew || data.isEditing" class="primary" dark dense>
                                <v-btn v-if="data.isNew || data.isEditing" icon large @click="() => { data.isNew ? $BlitzIt.navigation.navBack() : stopEditing() }">
                                    <v-icon large>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ data.isNew ? 'New Standing Order' : 'Standing Order' }}</v-toolbar-title>
                                <v-spacer />
                                <v-btn v-if="$vuetify.breakpoint.mobile && item.supplyAgreementID != null && (data.isNew || data.isEditing)" @click="() => { currentCol = (data.isNew || data.isEditing) ? 1 : 2 }" text>Next</v-btn>
                            </v-toolbar>
                            <v-card flat class="overflow-y-auto" :style="standardCardStyle">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <BT-Select-List-Box
                                            v-if="data.isNew"
                                            @change="agrID => { selectPolicy(agrID, item); }"
                                            :canDeselect="false"
                                            dense
                                            :isEditing="data.isNew || data.isEditing"
                                            :isSelecting="item.supplyAgreementID == null"
                                            itemText="seller.companyName"
                                            itemValue="id"
                                            label="Supplier"
                                            maxHeight="300px"
                                            navigation="suppliers"
                                            :onFilter="list => { return list ? list.orderBy(x => x.seller.companyName) : list; }"
                                            selectIfOneOption
                                            v-model="item.supplyAgreementID"
                                            width="900">
                                            <template v-slot:selectedItem="{ item, open }">
                                                <v-list-item @click="open">
                                                    <BT-List-Item-Avatar v-if="item != null" v-model="item.sellerID" />
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                        <v-list-item-title v-if="item != null">{{ item.seller ? item.seller.companyName : 'Select' }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                            <template v-slot="{ item }">
                                                <BT-List-Item-Avatar v-model="item.seller.id" />
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <span v-if="item.isSuspended" class="error--text">Suspended</span>
                                                </v-list-item-action>
                                            </template>
                                        </BT-Select-List-Box>
                                        <v-list-item v-else-if="item.seller != null" dense>
                                            <BT-List-Item-Avatar v-model="item.sellerID" />
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                <v-list-item-title>{{ item.seller.companyName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-else-if="currentPolicy != null" dense>
                                            <BT-List-Item-Avatar v-model="currentPolicy.sellerID" />
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Supplier</v-list-item-subtitle>
                                                <v-list-item-title>{{ currentPolicy.sellerName }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <BT-Field-String
                                            v-if="item.supplyAgreementID != null"
                                            v-model="item.standingOrderName"
                                            label="Standing Order Name"
                                            :isEditing="data.isNew || data.isEditing" />
                                    </v-col>
                                    <v-col v-if="!data.isNew || currentPolicy != null" cols="12">
                                        <BT-Field-Address v-if="(!data.isNew && !data.isEditing) || isArrayOfLength(possibleLocations, 1)"
                                            icon="mdi-map-marker"
                                            iconSize="40"
                                            label="Destination"
                                            :value="item.location" />
                                        <BT-Select-List-Box v-else
                                            v-model="item.locationID"
                                            :isEditing="data.isNew || data.isEditing"
                                            :isSelecting="item.locationID == null"
                                            :items="possibleLocations"
                                            itemValue="id"
                                            label="Destination"
                                            textFilter="toLocationLine">
                                            <template v-slot:selectedItem="{ item, open }">
                                                <v-list-item @click="open">
                                                    <v-list-item-avatar>
                                                        <v-icon size="40">mdi-map-marker</v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-subtitle>Destination</v-list-item-subtitle>
                                                        <v-list-item-title v-if="item != null">{{ item | toLocationLine }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon>mdi-chevron-down</v-icon>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                            <template v-slot="{ item }">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.locationName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ item | toLocationLine }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </BT-Select-List-Box>
                                    </v-col>
                                    <v-col v-if="item.supplyAgreementID != null" cols="12"> <!-- v-if="!data.isNew || currentPolicy != null" -->
                                        <v-subheader>Regularity</v-subheader>
                                        <BT-List
                                            v-if="(currentPolicy == null || currentPolicy.orderingArrangement !== 'Slotted') && isLengthyArray(item.orderSlots)"
                                            height="auto"
                                            :items="item.orderSlots">
                                            <template v-slot="{ item }">
                                                <v-list-item-icon><v-icon small class="success--text">mdi-check-bold</v-icon></v-list-item-icon>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ item.slotName }}</v-list-item-title>
                                                    <v-list-item-subtitle v-if="item.isOn">(Next: {{ item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else>Off</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </BT-List>

                                        <BT-List
                                            v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                                            height="auto"
                                            :items="filteredSlots">
                                            <template #listItem="e">
                                                <v-list-item @click="toggleSlot(e.item, item)">
                                                    <v-list-item-action>
                                                        <v-btn
                                                            :class="e.item.isSelected ? 'success--text' : 'red--text'"
                                                            :loading="e.item.loadingCount > 0"
                                                            icon>
                                                            <v-icon>{{ e.item.isSelected ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                                                        </v-btn>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ e.item.slotName }}</v-list-item-title>
                                                        <v-list-item-subtitle v-if="e.item.isOn">(Next: {{ e.item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                                        <v-list-item-subtitle v-else>Off</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot="e">
                                                <v-list-item-action>
                                                    <v-btn
                                                        @click="toggleSlot(e.item, item)"
                                                        :class="e.item.isSelected ? 'success--text' : 'red--text'"
                                                        :loading="e.item.loadingCount > 0"
                                                        icon
                                                        small>
                                                        <v-icon small>{{ e.item.isSelected ? 'mdi-check-bold' : 'mdi-close-thick' }}</v-icon>
                                                    </v-btn>
                                                </v-list-item-action>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ e.item.slotName }}</v-list-item-title>
                                                    <v-list-item-subtitle v-if="e.item.isOn">(Next: {{ e.item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else>Off</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </BT-List>
                                        
                                        <div v-else-if="(currentPolicy == null || currentPolicy.orderArrangement !== 'Slotted')">
                                            <BT-Field-Switch
                                                v-model="item.isOn"
                                                falseText="Off"
                                                :isEditing="data.isEditing || data.isNew"
                                                label="Status"
                                                trueText="On" />

                                            <BT-Field-Date
                                                v-if="item.isOn"
                                                v-model="item.nextAttentionDate"
                                                label="Next Occurrence"
                                                shortDateAndTime />
                                        
                                            <BT-Cron
                                                v-if="item.isOn || data.isEditing || data.isNew"
                                                v-model="item.cronExpression"
                                                :isEditing="data.isEditing || data.isNew"
                                                label="Regularity"
                                                leadTimeInHours
                                                leadTimeLeftLabel="Delivery Lead Time (Hours)"
                                                editExpression
                                                showExpression />

                                            <v-expansion-panels v-if="item.isOn || data.isEditing || data.isNew" v-model="panelI">
                                                <v-expansion-panel>
                                                    <v-expansion-panel-header>Adjustments</v-expansion-panel-header>
                                                    <v-expansion-panel-content>
                                                        <BT-Cron-Adjustments
                                                            v-model="item.adjustments"
                                                            :cronExpression="item.cronExpression"
                                                            leadTimeLeftLabel="Delivery Lead Time (Hours)"
                                                            :isEditing="data.isEditing || data.isNew"
                                                            leadTimeInHours />
                                                    </v-expansion-panel-content>
                                                </v-expansion-panel>
                                            </v-expansion-panels>

                                        </div>
                                    </v-col>
                                    <v-col v-if="data.isNew && item.supplyAgreementID != null" cols="12">
                                        <v-row no-gutters>
                                            <v-col v-if="$vuetify.breakpoint.mobile">
                                                <BT-Btn
                                                    icon="mdi-cart"
                                                    label="Select Products"
                                                    @click="currentCol = 1" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-if="$vuetify.breakpoint.mobile && !data.isNew && !data.isEditing" cols="12">
                                        <v-subheader>Ordered Items</v-subheader>
                                        <v-list>
                                            <template v-for="(orderItem, index) in item.orderItems">
                                                <Order-Line-Item
                                                    :item="orderItem"
                                                    showImages
                                                    :showPricing="currentPolicy != null"
                                                    :key="index"
                                                    @change="lineChanged" />
                                                <v-divider :key="'d' + orderItem.productID" />
                                            </template>
                                        </v-list>
                                    </v-col>
                                    <v-col v-if="$vuetify.breakpoint.mobile && !data.isNew && !data.isEditing && currentPolicy != null" class="text-right" cols="12">
                                        <p>Subtotal: {{ item.subTotal | toCurrency }}</p>
                                        <p>GST: {{ item.taxTotal | toCurrency }}</p>
                                        <v-divider class="my-1" />
                                        <div class="text-subtitle-1">Total: {{ item.amountTotal | toCurrency }}</div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="(data.isNew || data.isEditing) && currentPolicy != null && (!$vuetify.breakpoint.mobile || currentCol == 1)" key="2" class="flex-grow-1">
                        <v-card flat outlined>
                            <v-toolbar class="primary" dark dense>
                                <v-slide-x-transition>
                                    <v-btn v-if="currentCategory != null" large @click="currentCategory = null" icon>
                                        <v-icon large>mdi-arrow-left</v-icon>
                                    </v-btn>
                                    <v-btn v-else-if="$vuetify.breakpoint.mobile" large @click="currentCol = 0" icon>
                                        <v-icon large>mdi-arrow-left</v-icon>
                                    </v-btn>
                                </v-slide-x-transition>
                                <v-slide-x-transition hide-on-leave group>
                                    <v-toolbar-title v-if="currentCategory != null" key="1">{{ currentCategory.categoryName }}</v-toolbar-title>
                                    <v-toolbar-title v-else key="2">Products</v-toolbar-title>
                                </v-slide-x-transition>
                                <v-spacer />
                                <v-btn v-if="$vuetify.breakpoint.mobile" @click="currentCol = 2" text>Cart ({{ item.orderItems.length | toDisplayNumber }}) <v-icon right>mdi-arrow-right</v-icon></v-btn>
                            </v-toolbar>
                            <v-card class="overflow-y-auto" key="1" :style="standardCardStyle">
                                <v-slide-x-transition hide-on-leave group>
                                    <div v-if="canUseCategories && currentCategory == null" class="d-flex align-center justify-center" key="0">
                                        <v-btn
                                            @click="useCategories = false"
                                            :class="useCategories ? '' : 'success--text'"
                                            text>All</v-btn>
                                        |
                                        <v-btn
                                            @click="useCategories = true"
                                            :class="useCategories ? 'success--text' : ''"
                                            text>By Category</v-btn>
                                    </div>
                                    <v-list v-if="useCategories && currentCategory == null" dense key="1">
                                        <v-card v-for="(category, ind) in categories" :key="ind" @click="currentCategory = category" class="ma-1">
                                            <v-list-item dense three-line>
                                                <v-list-item-avatar rounded>
                                                    <v-img :src="productLogoURL(category.id)" class="my-auto">
                                                        <template v-slot:placeholder><v-icon size="40" class="primary--text">mdi-shape-outline</v-icon></template>
                                                    </v-img>
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ category.categoryName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ category.categoryDescription }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-card>
                                    </v-list>
                                    <v-list v-else-if="isLengthyArray(filteredOrderItems)" dense key="2">
                                        <template v-for="(orderItem, index) in filteredOrderItems">
                                            <Order-Line-Item 
                                                canEdit
                                                :item="orderItem"
                                                showImages
                                                :key="index"
                                                @change="lineChanged" />
                                            <v-divider :key="'d' + orderItem.productID" />
                                        </template>
                                    </v-list>
                                    <div v-else key="3">
                                        Nothing Available To This Customer
                                    </div>
                                </v-slide-x-transition>
                            </v-card>
                        </v-card>
                    </v-col>
                    <v-col v-if="!$vuetify.breakpoint.mobile || currentCol == 2" key="3" class="flex-shrink-1">
                        <v-card flat outlined>
                            <v-toolbar class="primary" dark dense>
                                <v-btn v-if="$vuetify.breakpoint.mobile" large @click="() => { currentCol = (data.isNew || data.isEditing) ? 1 : 0 }" icon>
                                    <v-icon large>mdi-arrow-left</v-icon>
                                </v-btn>
                                <v-toolbar-title>{{ data.isNew ? 'Cart' : 'Ordered Items' }} ({{ item.orderItems.length | toDisplayNumber }})</v-toolbar-title>
                                <v-spacer />
                                <v-icon>mdi-cart</v-icon>
                            </v-toolbar>
                            <v-card class="overflow-y-auto" :style="standardCardStyle">
                                <v-list v-if="(data.isNew || data.isEditing) && isLengthyArray(currentOrderItems.filter(x => x.quantity != 0))" dense>
                                    <v-slide-y-transition group>
                                        <template v-for="(orderItem, index) in currentOrderItems">
                                            <Order-Line-Item 
                                                canEdit
                                                :item="orderItem"
                                                showImages
                                                :key="index"
                                                @change="lineChanged" />
                                            <v-divider :key="'d' + orderItem.productID" />
                                        </template>
                                    </v-slide-y-transition>
                                </v-list>
                                <v-list v-else-if="isLengthyArray(item.orderItems)" dense>
                                    <template v-for="(orderItem, index) in item.orderItems">
                                        <Order-Line-Item
                                            :item="orderItem"
                                            showImages
                                            :key="index"
                                            @change="lineChanged" />
                                        <v-divider :key="'d' + orderItem.productID" />
                                    </template>
                                </v-list>
                                <v-card v-else flat class="align-content-center justify-content-center text-center">
                                    <v-card-text>
                                        <div class="d-flex flex-column text-center">
                                            <v-icon size="100">mdi-cart</v-icon>
                                            No Order Yet
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-row v-if="isLengthyArray(item.orderItems)" no-gutters key="4" class="mx-4">
                                    <!-- <v-col cols="12">
                                        <v-alert :value="showError" dismissible type="error">{{ errorMsg }}</v-alert>
                                    </v-col> -->
                                    <v-col cols="6" class="d-flex justify-center">
                                        <BT-Btn
                                            v-if="data.isNew || data.isEditing"
                                            @click="() => save(false)"
                                            block
                                            class="primary"
                                            :disabled="!isChanged"
                                            inline
                                            :label="item.id == null ? 'Save' : 'Update'" />
                                        <BT-Btn
                                            v-if="!data.isNew && item.isDispatched"
                                            bladeName="stock-import"
                                            icon="mdi-clipboard-outline"
                                            :id="item.id"
                                            label="Import Stock"
                                            nav />
                                    </v-col>
                                    <v-col class="text-right" cols="6">
                                        <p>Subtotal: {{ item.subTotal | toCurrency }}</p>
                                        <p>GST: {{ item.taxTotal | toCurrency }}</p>
                                        <v-divider class="my-1" />
                                        <div class="text-subtitle-1">Total: {{ item.amountTotal | toCurrency }}</div>
                                    </v-col>
                                    <v-col v-if="msg != null" cols="12">
                                        <v-alert color="error" dark>{{ msg }}</v-alert>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-card>
                    </v-col>
                    <!-- <v-col v-if="!data.isEditing && !data.isNew" key="4">
                        <v-card flat outlined>
                            
                        </v-card>
                    </v-col> -->
                </v-slide-x-reverse-transition>
            </v-container>
        </template>
        <template v-slot:page="{ item, data }">
            <v-dialog transition="dialog-bottom-transition" max-width="600" persistent v-model="showFinalDialog" @input="$BlitzIt.navigation.navBack">
                <template #default="dialog">
                    <v-card>
                        <v-card-text class="text-center">
                            <v-slide-x-reverse-transition>
                                <v-card v-if="finalColumn == 0" flat outlined class="text-center">
                                    <v-icon size="80" class="success--text mt-4">mdi-check-decagram-outline</v-icon>
                                    <div class="text-h5">Order {{ data.isNew ? 'Placed!' : 'Updated!' }}</div>
                                    <!-- <a v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'" @click="finalColumn = 1">Make this my standing order</a> -->
                                </v-card>
                                <v-card v-else-if="finalColumn == 1">
                                    <BT-List
                                        v-if="currentPolicy != null && currentPolicy.orderingArrangement === 'Slotted'"
                                        customURL="/StandingOrderPolicy/StandingOrderPolicy"
                                        isSingle
                                        :itemID="item.supplyAgreementID"
                                        navigation="suppliers"
                                        :onPullSuccessAsync="res => res.slots.filter(x => x.isOn)">
                                        <template v-slot:listItem="e">
                                            <v-list-item @click="saveAsStandingOrder(item, e.item)">
                                                <v-list-item-avatar>

                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ e.item.slotName }}</v-list-item-title>
                                                    <v-list-item-subtitle>(Next: {{ e.item.nextAttentionDate | toShortDateAndTime }})</v-list-item-subtitle>
                                                    <v-list-item-subtitle>{{ e.item.standingOrderIDs }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </template>
                                    </BT-List>
                                </v-card>
                            </v-slide-x-reverse-transition>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="dialog.value = false" block class="primary">OK</v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-overlay :value="finalLoadingMsg != null" class="text-center">
                        <v-progress-circular indeterminate size="64" />
                        <p>{{ finalLoadingMsg }}</p>
                    </v-overlay>
                </template>
            </v-dialog>
        </template>
    </BT-Blade-Item>
</template>

<script>
import moment from 'moment-timezone';
import { calculateAutoItems, loadOrderItems, recalculateOrder, updateOrder } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'Standing-Purchase-Order-Blade',
    components: {
        OrderLineItem: () => import('~home/purchase-orders/Ordering-Line-Item.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue')
    },
    data: () => {
        return {
            allOrderItems: [],
            autoOrderItems: [],
            canUseCategories: false,
            categories: [],
            currentCategory: null,
            currentCol: 0,
            currentOrder: null,
            currentPolicy: null,
            dateFrom: null,
            dateRules: null,
            dueDateOptions: [],
            editToggle: false,
            errorMsg: null,
            filteredSlots: [],
            hideBladeHeader: false,
            loadingMsg: null,
            mAgreementID: null,
            msg: null,
            panelI: [],
            panelV: [1],
            showError: false,
            showFinalDialog: false,
            useCategories: true
        }
    },
    props: {
        bladesData: null
    },
    computed: {
        currentOrderItems() {
            return this.allOrderItems.filter(x => x.quantity != 0)
        },
        filteredOrderItems() {
            if (!this.isLengthyArray(this.categories) || !this.useCategories) {
                return this.allOrderItems;
            }
            else if (this.currentCategory != null) {
                if (this.currentCategory.id == 'other') {
                    return this.allOrderItems.filter(z => z.data.product == null || z.data.product.categoryID == null);
                }
                else {
                    return this.allOrderItems.filter(z => z.data.product != null && z.data.product.categoryID == this.currentCategory.id);
                }
            }

            return [];
        },
        possibleLocations() {
            return this.currentPolicy != null ? this.currentPolicy.possibleLocations : [];
        },
        standardCardStyle() {
            return 'height: calc(100vh - 160px)';
        },
        title() {
            if (this.currentOrder?.id == null) {
                return 'New Standing Order';
            }
            else {
                return 'Standing Order';
            }
        }
    },
    methods: {
        getRules(oItem) {
            const rules = []
            if (oItem.data.rules != null) {
                var incRule = oItem.data.rules.find(x => x.rule == 'QuantityInIncrements')
                if (incRule) {
                    rules.push(v => v % incRule.quantity == 0 || `Must order ${oItem.data.product?.productName} in increments of ${incRule.quantity}`)
                }

                var moqRule = oItem.data.rules.find(x => x.rule == 'QuantityMoreThan')
                if (moqRule) {
                    rules.push(v => v == 0 || v >= moqRule.quantity || `Minimum order quantity for ${oItem.data.product?.productName} is ${moqRule.quantity}`)
                }
            }

            return rules
        },
        canSaveOrder(order) {
            this.errorMsg = null;

            if (order == null) {
                this.errorMsg = 'No order to save'
            }

            if (order != null && this.isLengthyArray(this.allOrderItems)) {
                const ruleResults = this.allOrderItems.flatMap(x => {
                    const rules = this.getRules(x)
                    return rules.map(r => r(x.quantity))
                })
                
                const strings = ruleResults.filter(x => x !== true)
                if (this.isLengthyArray(strings))
                    return strings[0]
            }

            if (this.currentPolicy != null &&
                this.currentPolicy.orderingArrangement == 'Slotted' &&
                !this.isLengthyArray(order.orderSlots)) {
                this.errorMsg = 'Please specify how regular this order will be by selecting an order slot'
            }
            
            this.showError = this.errorMsg != null
            return this.errorMsg
        },
        stopEditing() {
            this.editToggle = !this.editToggle;
            this.hideBladeHeader = false;
        },
        getOrderToSave(bladeData, order) {
            order.orderItems = order.orderItems.filter(x => x.productID != null);
            return order;
        },
        async edit(bladeData, order) {
            console.log('editing');
            if (order != null && order.id != null) {
                var resData = await this.$BlitzIt.api.getById('suppliers', order.supplyAgreementID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                var policy = resData.data.data;

                if (policy.orderingArrangement === 'Slotted') {
                    this.filteredSlots = [];
                    policy.slots.forEach(slot => {
                        this.filteredSlots.push({
                            loading: false,
                            isSelected: order.orderSlots.some(orderSlot => orderSlot.id == slot.id), //&& slot.standingOrderIDs.some(y => y == order.id),
                            ...slot
                        });
                    });

                    if (order != null && this.isLengthyArray(order.orderSlots)) {
                        order.orderSlots.forEach(eSlot => {
                            if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
                                this.filteredSlots.unshift({
                                    loading: false,
                                    isSelected: true,
                                    ...eSlot
                                });
                            }
                        })
                    }
                }
                else if (policy.requireLeadTime) {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }
                else {
                    this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    if (!policy.isAvailableWeekends) {
                        this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    }
                }

                this.currentPolicy = policy;
console.log('recalculating');
                var res = recalculateOrder(order, policy);
                order.orderItems = res.orderItems;
                order.taxTotal = res.taxTotal;
                order.subTotal = res.subTotal;
                order.amountTotal = res.amountTotal;

                console.log(order);
            }
        },
        async loadPolicy(bladeData, order) {
            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', order.supplyAgreementID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                await this.setPolicy(policy, order)

                this.hideBladeHeader = true;

                // if (policy.orderingArrangement === 'Slotted') {
                //     for (var i = 0; i < policy.orderSlots.length; i++) {
                //         var slot = policy.orderSlots[i];
                //         if (!slot.purchaseOrderID) {
                //             this.dueDateOptions.unshift(slot.deliverBy);
                //         }
                //     }
                    
                //     this.dueDateOptions.sort();
                // }
                // else if (policy.requireLeadTime) {
                //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                //     if (!policy.isAvailableWeekends) {
                //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                //     }
                // }
                // else {
                //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                //     if (!policy.isAvailableWeekends) {
                //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                //     }
                // }

                // this.currentPolicy = policy;
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async selectPolicy(agrID, order) {
            if (agrID == null) {
                this.msg = 'No supplier selected';
                return;
            }

            try {
                this.loadingMsg = 'Loading Policy';

                var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                var policy = resData.data.data;
                
                if (policy == null) {
                    this.msg = 'No policy found';
                    return;
                }

                order.isBuyerCourier = policy.isRequirePickup ? true : policy.isPreferPickup,
                order.locationID = policy.defaultLocationID;
                order.location = policy.defaultLocation;
                order.orderItems = [];
                order.subTotal = 0;
                order.taxTotal = 0;
                order.amountTotal = 0;
                order.supplyAgreementID = agrID;

                await this.setPolicy(policy, order);
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null
            }
        },
        async setPolicy(policy, order) {
            console.log('setting policy');
            console.log(policy);
            // if (policy.orderingArrangement === 'Slotted') {
            //     for (var i = 0; i < policy.orderSlots.length; i++) {
            //         var slot = policy.orderSlots[i];
            //         if (!slot.purchaseOrderID) {
            //             this.dueDateOptions.unshift(slot.deliverBy);
            //         }
            //     }
                
            //     this.dueDateOptions.sort();
            // }
            // else if (policy.requireLeadTime) {
            //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

            //     if (!policy.isAvailableWeekends) {
            //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
            //     }
            // }
            // else {
            //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

            //     if (!policy.isAvailableWeekends) {
            //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
            //     }
            // }

            if (policy.orderingArrangement === 'Slotted') {
                this.filteredSlots = [];
                policy.slots.forEach(slot => {
                    this.filteredSlots.push({
                        loading: false,
                        isSelected: order != null && order.id != null && order.orderSlots.some(orderSlot => orderSlot.id == slot.id),
                        ...slot
                    });
                });

                if (order != null && this.isLengthyArray(order.orderSlots)) {
                    order.orderSlots.forEach(eSlot => {
                        if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
                            this.filteredSlots.unshift({
                                loading: false,
                                isSelected: true,
                                ...eSlot
                            });
                        }
                    })
                }
            }
            else if (policy.requireLeadTime) {
                this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                if (!policy.isAvailableWeekends) {
                    this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                }
            }
            else {
                this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                if (!policy.isAvailableWeekends) {
                    this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                }
            }

            this.currentPolicy = policy;

            //prep order items
            this.currentCategory = null;
            this.useCategories = true;

            this.allOrderItems = loadOrderItems(order, policy);

            this.autoOrderItems = calculateAutoItems(policy, this.allOrderItems, order);

            //update order?
            updateOrder(order, policy, this.allOrderItems, this.autoOrderItems);

            var categoryIDs = [...new Set(policy.orderItems.filter(z => z.product != null && z.product.categoryID != null).map(x => x.product.categoryID))];
            this.canUseCategories = this.isLengthyArray(categoryIDs);
            
            if (this.isLengthyArray(categoryIDs)) {
                this.categories = await this.$BlitzIt.store.getAll('public-product-categories', { ids: categoryIDs.toString() });

                if (this.isLengthyArray(this.categories)) {
                    if (policy.orderItems.some(z => z.product == null || z.product.categoryID == null)) {
                        this.categories.push({
                            id: 'other',
                            categoryName: 'Other'
                        });
                    }
                }
            }
            else {
                this.useCategories = false;
            }
        },
        async newOrder(bladeData, order) {
            this.hideBladeHeader = true;

            try {
                var slotIDs = [];
                var blankOrder = null;
                var agrID = null;

                agrID = bladeData.data?.agreementID;

                if (bladeData != null && bladeData.data != null && bladeData.data.orderSlotID != null) {
                    slotIDs.push(bladeData.data.orderSlotID);
                }

                blankOrder = {
                    id: null,
                    isBuyerCourier: false,
                    locationID: null,
                    location: null,
                    orderItems: [],
                    supplyAgreementID: null,
                    orderSlotIDs: slotIDs
                };
                
                this.loadingMsg = '...preparing for new standing order';

                if (agrID == null) {
                    return blankOrder;
                }
                else {
                    var resData = await this.$BlitzIt.api.getById('suppliers', agrID, null, null, '/StandingOrderPolicy/StandingOrderPolicy');
                    var policy = resData.data.data;

                    if (policy == null) {
                        return blankOrder;
                    }

                    order = {
                        isBuyerCourier: policy.isRequirePickup ? true : policy.isPreferPickup,
                        locationID: policy.defaultLocationID,
                        location: policy.defaultLocation,
                        orderItems: [],
                        supplyAgreementID: agrID
                    };

                    if (policy.orderingArrangement === 'Slotted') {
                        this.filteredSlots = [];
                        policy.slots.forEach(slot => {
                            this.filteredSlots.push({
                                loading: false,
                                isSelected: order != null && order.id != null && order.orderSlots.some(orderSlot => orderSlot.id == slot.id), //&& slot.standingOrderIDs.some(y => y == order.id),
                                ...slot
                            });
                        });

                        if (order != null && this.isLengthyArray(order.orderSlots)) {
                            order.orderSlots.forEach(eSlot => {
                                if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
                                    this.filteredSlots.unshift({
                                        loading: false,
                                        isSelected: true,
                                        ...eSlot
                                    });
                                }
                            })
                        }
                    }
                    else if (policy.requireLeadTime) {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }
                    else {
                        this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                        if (!policy.isAvailableWeekends) {
                            this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                        }
                    }

                    // if (policy.orderingArrangement === 'Slotted') {
                    //     for (var i = 0; i < policy.orderSlots.length; i++) {
                    //         var slot = policy.orderSlots[i];
                    //         if (!slot.purchaseOrderID) {
                    //             this.dueDateOptions.unshift(slot.deliverBy);
                    //         }
                    //     }
                      
                    //     this.dueDateOptions.sort();
                    // }
                    // else if (policy.requireLeadTime) {
                    //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

                    //     if (!policy.isAvailableWeekends) {
                    //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    //     }
                    // }
                    // else {
                    //     this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

                    //     if (!policy.isAvailableWeekends) {
                    //         this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
                    //     }
                    // }
console.log('newing');
                    order = recalculateOrder(order, policy);
                    
                    await this.setPolicy(policy, order);

                    return order;
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }

            // var slotIDs = [];
            
            // if (bladeData != null && bladeData.data != null && bladeData.data.orderSlotID != null) {
            //     slotIDs.push(bladeData.data.orderSlotID);
            // }

            // return {
            //     id: null,
            //     isBuyerCourier: false,
            //     locationID: null,
            //     location: null,
            //     orderItems: [],
            //     supplyAgreementID: null,
            //     orderSlotIDs: slotIDs
            // }
        },
        async orderPulled(order) {
            if (order != null && this.isLengthyArray(order.orderItems)) {
                order.orderItems.sort(firstBy(x => x.product.sortNumber));
                order.orderItems = order.orderItems.map(x => Object.assign({}, x, { description: x.product ? x.product.productName: null, unitPrice: 0 }));
            }

            this.currentOrder = order;

            return order;

            // this.currentPolicy = null;
            // this.dateFrom = null;
            // this.filteredSlots = [];
            // this.dateRules = null;
            
            // try {
            //     this.loadingMsg = 'Loading';

            //     if (order != null && (order.id == null || order.id == 'new')) {
                    
            //         if (policy.orderingArrangement === 'Slotted') {
            //             this.filteredSlots = [];
            //             policy.slots.forEach(slot => {
            //                 this.filteredSlots.push({
            //                     loading: false,
            //                     isSelected: order != null && order.id != null && order.orderSlots.some(orderSlot => orderSlot.id == slot.id), //&& slot.standingOrderIDs.some(y => y == order.id),
            //                     ...slot
            //                 });
            //             });

            //             if (order != null && this.isLengthyArray(order.orderSlots)) {
            //                 order.orderSlots.forEach(eSlot => {
            //                     if (!this.filteredSlots.some(z => z.id == eSlot.id)) {
            //                         this.filteredSlots.unshift({
            //                             loading: false,
            //                             isSelected: true,
            //                             ...eSlot
            //                         });
            //                     }
            //                 })
            //             }
            //         }
            //         else if (policy.requireLeadTime) {
            //             this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, policy.orderLeadHours);

            //             if (!policy.isAvailableWeekends) {
            //                 this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
            //             }
            //         }
            //         else {
            //             this.dateFrom = this.$BlitzIt.auth.createUTC('yyyy-LL-dd', 0, 0);

            //             if (!policy.isAvailableWeekends) {
            //                 this.dateRules = x => moment(x).day() !== 0 && moment(x).day() !== 6;
            //             }
            //         }

            //         this.currentPolicy = policy;
            //     }
            //     else if (order != null) {
            //         order.orderItems.sort(firstBy(x => x.product.sortNumber));
            //         order.orderItems = order.orderItems.map(x => Object.assign({}, x, { description: x.product ? x.product.productName: null, unitPrice: 0 }));
            //     }

            //     if (this.currentPolicy != null) {
            //         order = recalculateOrder(order, this.currentPolicy);
            //         console.log('calc');
            //         console.log(order);
            //     }

            //     return order;
            // }
            // catch (err) {
            //     this.msg = this.extractErrorDescription(err);
            // }
            // finally {
            //     this.loadingMsg = null;
            // }
        },
        async pullSuppliers(list, order) {
            //move on if only 1 supplier
            if (this.isArrayOfLength(list, 1)) {
                await this.selectPolicy(list[0].id, order);
            }

            return list;
        },
        async toggleSlot(slot, item) {
            if (item.id == null) {
                var e = item.orderSlotIDs.findIndex(x => x == slot.id);
                if (e >= 0) {
                    slot.isSelected = false;
                    item.orderSlotIDs.splice(e, 1);
                }
                else {
                    slot.isSelected = true;
                    item.orderSlotIDs.push(slot.id);
                }
            }
            else {
                try {
                    slot.loadingCount += 1; // = true;
                    this.$forceUpdate();

                    var url = slot.isSelected ? '/post/Remove' : '/post/Add';

                    await this.$BlitzIt.api.post('standing-purchase-orders', { orderSlotID: slot.id, standingOrderID: item.id }, null, url);

                    slot.isSelected = !slot.isSelected;
                }
                catch (err) {
                    this.msg = this.extractErrorDescription(err);
                }
                finally {
                    slot.loading = false;
                    this.$forceUpdate();
                }
            }
        },
        lineChanged() {
            this.autoOrderItems = calculateAutoItems(this.currentPolicy, this.allOrderItems, this.currentOrder)

            updateOrder(
                this.currentOrder,
                this.currentPolicy,
                this.allOrderItems,
                this.autoOrderItems
            );
        },
        async updateCurrentOrder(order, saveFunc = null) {
            this.currentOrder.orderItems = order.orderItems;
            this.currentOrder.taxTotal = order.taxTotal;
            this.currentOrder.subTotal = order.subTotal;
            this.currentOrder.amountTotal = order.amountTotal;

            if (saveFunc != null) {
                await saveFunc
            }
        }
     }
}
</script>